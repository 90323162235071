.f-card {
  cursor: pointer;
  transition: all;
  transition-duration: 100ms;
}

.f-card:hover {
  border-radius: 16px;
  background: #168004;
  box-shadow: 0px 5px 10px 0px rgba(52, 52, 52, 0.1);
}

.f-card:hover div svg path {
  fill: #fff !important;
}

.f-card:hover .feature-content h4,
.f-card:hover .feature-content p {
  color: white;
}
