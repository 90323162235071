.background-image {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow: hidden; 
    background-image: url('../../assets/images/ComingSoonBg.png');
}
  
.page-title {
    color: var(--White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 124px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

.text{
    color: #C7C7C7;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 36px;
    letter-spacing: 0.2px;
}  

.stay-tuned{
  color: #00B960;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0.2px;
}

.notification-text{
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
}

.button-comming-soon{
  background: #00B960;
}

.notification{
  /* display: block;
  margin: auto; */
  min-width: 480px;
}

.unit-box{
  min-width: 160px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.40);
  backdrop-filter: blur(21.423568725585938px);
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  padding: 26px 0;
  letter-spacing: 0.4px;
  line-height: normal;    
  font-style: normal;
}

.unit-box span{
  font-size: 18px;
  font-weight: 400;
}

.unit-box p{
  font-size: 42px;
  font-weight: 700;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .page-title {
    font-size: 90px; 
  }

  .text{
    font-size: 18px;
  } 

  .stay-tuned{
    font-size: 18px;
  } 
}

@media (max-width: 768px) {
  .page-title {
    font-size: 48px; 
  }

  .text{
    font-size: 16px;
    max-width: 400px;
  } 

  .notification{
    min-width: 330px;
  }

  .stay-tuned{
    font-size: 16px;
  } 

  .notification-text{
    font-size: 14px;
  }

  .unit-box{
    min-width: 110px;
  }
  
  .unit-box span{
    font-size: 16px;
    font-weight: 400;
  }
  
  .unit-box p{
    font-size: 36px;
    font-weight: 700;
  }
}