.login-title {
  color: #168004;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 120% */
  letter-spacing: -0.6px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .login-title {
    font-size: 30px;
  }
}
.login-form {
  display: block;
  margin: auto;
}
.signUp-button {
  width: 295px;
  height: 48px;
  border-radius: 8px;
  background: #168004;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.543px;
  text-transform: uppercase;
}
.signup-text {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 83.333% */
}
.signup-text-details {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.login-button {
  border-radius: 8px;
  background: #3e8936;
  height: 55px !important;
  color: #fafafb;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5px;
}
.form-input-register {
  width: 100%;
  height: 45px;
}
@media (max-width: 768px) {
  .form-input-register {
    transition: #f7fbf7 0.3s ease;
    width: 100%;
  }
}

input[type="checkbox"] {
  accent-color: #3e8936;
}

input {
  outline: none;
}

.form-input-register:focus {
  border: 1px solid #3e8936;
}

.form-label {
  color: var(--Grey, #808d9e);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5px;
}
.forgot-password {
  color: #3e8936;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5px;
}
.social-media {
  color: #212121;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.image-register {
  clip-path: polygon(0 0, 83% 0, 98% 100%, 99% 100%, 0 100%, 0% 50%) !important;
}

.input-container {
  position: relative;
}

.icon-auth {
  width: 1.4em;
  height: 1.4em;
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .icon {
    width: 1.2em;
    height: 1.2em;
    right: 0.5em;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .icon {
    width: 1.4em;
    height: 1.4em;
    right: 1em;
  }
}

.background-image-register {
  background-size: 50%;
  background-position: top right;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
}

.background-register {
  background-image: url("../../assets/images/BgRegister.png");
}
