.icon-container {
  border-radius: 20px;
  padding: 20px;
  max-width: 82px;
}
.icon-container1 {
  border-radius: 20px;
  background: rgba(255, 98, 80, 0.2);
  padding: 20px;
  max-width: 82px;
}
.icon-container2 {
  border-radius: 12.115px;
  background: rgba(22, 128, 4, 0.16);
  padding: 20px;
  max-width: 82px;
}
.icon-container3 {
  border-radius: 17.459px;
  background: rgba(248, 213, 126, 0.2);
  padding: 20px;
  max-width: 82px;
}

.spectrum-card {
  padding: 20px 30px;
  border-radius: 20px;
  border: 1px solid var(--CTA---Aqua-Squeeze, #e5f4f2);
  background: #fff;
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(0, 0, 0, 0.04);
}
