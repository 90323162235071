.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.3s ease;
}

.navbar.scrolled {
  background-color: white;
}

.navbar.active {
  top: -60px;
}
.Login-header-button {
  border-radius: 8px;
  border: 1px solid #168004;
  background: #fff;
  padding: 12px 20px;
  color: #111;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 19.2px */
}

.Login-header-button:hover {
  border: 1px solid #168004;
  background: rgba(22, 128, 4, 0.18);
  /* Change the color of the pricing-button to white on hover */
}

.demo-button {
  border-radius: 8px;
  background: #168004;
  border: 1px solid #168004;
  padding: 12px 12px;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.dashboard-button {
  border-radius: 8px;
  background: #168004;
  border: 1px solid #168004;
  width: 200px;
  padding: 12px 12px;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.nav-titles {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #403e3e;
  cursor: pointer;
  position: relative;
}

.nav-titles::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #168004;
  border-radius: 5px;
  transform: scaleX(0);
  transition: all 0.6s ease;
  bottom: 0;
  left: 0;
}

.nav-titles:hover::before {
  transform: scaleX(1);
}

.nav-titles:active {
  color: #168004;
  font-weight: 600;
}

.navButton {
  left: em;
}

.navIcon {
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 4px;
  border: 0.77px solid #168004;
  background: var(--extra-light-grey, #f3f4f637);
}

@media (max-width: 768px) {
  .Header-logo {
    height: 55px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Header-logo {
    height: 55px;
  }
}
.nav-dropdown {
  background-color: #86efac;
}

.Login-button-header {
  border-radius: 8px;
  border: 1px solid #168004;
  background: #168004;
  width: 100%;
  padding: 12px 12px;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
}
.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  /*display: none;*/
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #2980b9;
}
