.connect {
  cursor: pointer;
  transition: all;
  transition-duration: 100ms;
}
.connect:hover svg g path,
.connect:hover svg path {
  fill: #168004;
}

.form {
  border-radius: 24px;
  background: var(--Gray-00, #fff);
  box-shadow: 0px 16px 72px 0px rgba(6, 28, 61, 0.08);
}

.input {
  width: 100%;
  padding: 12px 18px;
  border-radius: 5px;
  border: 1px solid var(--Gray-100, #e6e8ec);
  background: var(--Gray-00, #fff);
}

.rotation{
  transform: rotate(45deg);
}