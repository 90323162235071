.background-image-header {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden; /* Add this property */
}

.background-header {
  background-image: url("../../../assets/images/bg-header.svg");
  background-size: cover; /* Add this property to make sure the image covers the container */
}
.Header-title {
  color: #111;
  font-family: Inter;
  font-size: 42.327px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.5%; /* 56.506px */
  letter-spacing: -0.529px;
}
.header-button {
  border-radius: 8px;
  background: #168004;
  max-width: max-content;
}
.image-header {
  display: block;
  margin: auto;
}
.header-description {
  color: #000;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 181.5%; /* 34.485px */
}

.welcome {
  background: rgba(22, 128, 4, 0.16);
}

.hero-img {
  filter: drop-shadow(0px 4.349px 28.92px rgba(0, 0, 0, 0.25));
}
