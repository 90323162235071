
  .documentation-menu-items {
    position: fixed;
    top: 110px;
    left: 0;
    padding: 10px;
    height:100%;
    background-color: #fff; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }
  
  .menu-item {
    display: block;
    text-decoration: none;
    color: #403e3e; 
    /* font-family: 'Inter'; */
    font-size: 18px;    
    font-weight: 600;
    padding-top: 30px;
    font-style: normal;
    margin-bottom: 10px; 
    text-align:left;
    padding:20px;
  } 
  .menu-item:hover {
    text-decoration: underline;
  }
  
  .menu-item.active {
    text-decoration: underline;
  }
  

.container {
    width: 100%;
}

.item {
    margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
    .item {
        font-size: 14px;
    }
}
.title{
color: #000;
/*font-family: 'Inter';*/
font-size: 18px;
font-style: normal;
font-weight: 700;
text-decoration-line: underline;
}

.commande{
    display: block;
    margin: auto;
}

.details{
    color: #636365;
    /* font-family: 'Inter'; */
    font-size: 16px;
}

@media only screen and (max-width: 768px) {
    .lg\:mx-60 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .text-start {
        flex-direction: column; 
    }
}
