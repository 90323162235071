.board-container {
  border-radius: 15px;
  background: rgba(202, 202, 202, 0.16);
}
.board-icon {
  width: 50px;
  height: 50px;
  background: rgba(78, 125, 150, 0.1);
}
.boardIcon {
  display: block;
  margin: auto;
}
.chartContainer {
  border-radius: 12px;
  background: #f7f7f7;
}
.drawerItem {
  border-radius: 6px;
  background: #f7f7f7;
}
.drawer {
  background: rgba(109, 169, 35, 0.16);
}
.search-filed {
  border: none !important;
  border-radius: 11px !important;
  background: #fff !important;
}

.search-wrapper {
  border-radius: 12px !important;
  padding: 5px 10px !important;
}

.optionContainer .option:hover {
  background-color: #f0f0f0 !important;
}
