.modal-title{
color: #202020;
font-family: Inter;
font-size: 28px;
font-style: bold;
font-weight: 700;
line-height: 44px; 
}

.modal-label{
color:  #444242;
font-family: Inter;
font-size: 12px;
font-style: medium;
font-weight: 600;
line-height: 21px;
}
.import-text{
color: var(--grey-grey-10, #272725);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px;
}
.delete-board{
color: #1d1c1c;
font-family: Inter;
font-size: 25px;
font-style: bold;
font-weight: 500;
line-height: 35px;
text-transform: capitalize;
}
.profile-picture{
width: 130px;
height: 125px;
border-radius: 18px;
border: 1px dashed #4C535F;
background: #EDF2F6;
}
.picture{
    display: block;
    margin: auto;
}
#colorstyle{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    width:40px;
    height: 40px;
    border: none;
    cursor: pointer;
    border-radius: 50% !important;
}
#colorstyle::-webkit-color-swatch{
    border-radius: 50% !important;


}
#colorstyle::-moz-color-swatch{
    border-radius: 50%;
    

}
:root {
    --primary-color: var(blue);
    --secondary-color: var(--text-theme-default);
  }
  
  .bg-theme-default {
    background-color: var(blue);
  }
  
  .text-theme-default {
    color: var(--secondary-color);
  }