@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.light {
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* SIDEBAR */
/* .sidebarItem:hover .icon {
  stroke: rgba(22, 128, 4, 0.65);
}
.sidebarItem:hover .icon path {
  stroke: rgba(22, 128, 4, 0.65);
} */

.sidebarItem.notActive .icon,
.sidebarItem.notActive span {
  stroke: #5c5c5c;
  color: #5c5c5c;
}

.dark .sidebarItem.notActive span {
  stroke: white;
  color: white;
}

.sidebarItem .icon path,
.sidebarItem span {
  stroke: #5c5c5c;
}

/* MAIN CONTENT */

/* Table */
.dark .MuiPaper-root {
  background-color: #1d221c !important;
  box-shadow: none !important;
}
.Mui-selected {
  font-weight: bold !important;
  font-size: 14px !important;
}
.dark .Mui-selected {
  color: #949fb6 !important;
}
.light .Mui-selected {
  color: #949fb6 !important;
}
.dark .MuiTableCell-root {
  color: white !important;
  text-align: start !important;
}

.light .MuiTableCell-root {
  color: black !important;
}

.dark .css-l4p1f4 {
  background-color: #1d221c !important;
}

.light .css-l4p1f4 {
  background-color: #fff !important;
}

.light .PhoneInputInput {
  background-color: #f5f5f5 !important;
}

.dark .PhoneInputInput {
  background-color: #4a4a4a !important;
}

/* For light mode */
.light .MuiPaper-root {
  background-color: #ffffff !important;
}

.MuiTableCell-head {
  color: #b5b7c0 !important;
  font-size: 15.967px;
  font-style: normal;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.css-1ygcj2i-MuiTableCell-root,
.css-1ex1afd-MuiTableCell-root {
  text-align: center !important;
  font-size: 15.967px !important;
}

.css-1ex1afd-MuiTableCell-root {
  color: #292d32 !important;

  font-family: Poppins;
  font-size: 15.967px !important;
  font-style: normal;
  font-weight: 500 !important;
}

.css-rqglhn-MuiTable-root {
  margin: 0px 10px;
}

/* pagination */
/* .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  border-radius: 4px;
  border: 1.14px solid var(--user-color) !important;
  color: inherit !important;
  background: var(--user-color) !important;
} */

li button[aria-current="true"] {
  border-radius: 4px;
  border: 1.14px solid var(--user-color) !important;
  color: #c2cfeb !important;
  background: transparent !important;
}

.MuiPaginationItem-previousNext {
  background: #f5f5f5 !important;
  color: #3f4548 !important;
  border-radius: 4px;
  border: 1.14px solid #eee !important;
}

/* .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  border-radius: 4px;
  border: 1.14px solid #eee !important;
  color: #3f4548 !important;
  background: #f5f5f5 !important;
} */

/* checkbox */
.Mui-selected td span svg,
.css-i4bv87-MuiSvgIcon-root {
  color: #168004;
}

/* Modal */

@media screen and (max-width: 900px) {
  .modal .css-l4p1f4,
  .modal .css-1do2mnl,
  .MuiBox-root {
    width: 80% !important;
  }
}

@media screen and (max-width: 466px) {
  .modal-cancel {
    margin-top: 1rem;
  }
}

.input-key {
  border-radius: 8px !important;
  border: 1px solid #168004;
  height: 54px !important;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: #fafafa;
  height: 45px;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  /* border: 1px solid #ccc; */
  padding: 0.5rem;
  border-radius: 0;
}

/* Settings */
@media screen and (max-width: 585px) {
  .profile-form {
    gap: 10px;
  }

  .profile-btns {
    margin: 30px 0;
  }

  .profile-section {
    padding-bottom: 20px;
  }
}

.PhoneInput {
  display: flex;
}

.PhoneInputCountry {
  display: flex;
}

.PhoneInputCountrySelect {
  width: 50px !important;
}

.PhoneInputCountryIconImg {
  width: 40px;
}

.PhoneInputInput {
  outline: none;
}

.MuiOutlinedInput-root {
  height: 44px !important;
  outline: none !important;
}

/* Historical */
.date-range-picker {
  background-color: #168004 !important;
  color: white !important;
  border-radius: 16px !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.dark-input {
  color: #ffffff;
  /* Change text color in dark mode */
  background-color: #333333;
  /* Change background color in dark mode */
  /* Add any other styles for dark mode inputs */
}

.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
  transform: scale(0.85);
  display: none !important;
}

.apexcharts-toolbar {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .header-right-container {
    margin-right: 2rem;
  }
}
.css-1rv8zyt-MuiStack-root > :not(style):not(style) {
  margin: 0;
  /* margin-left: 20px !important; */
}
.slick-prev,
.slick-next {
  display: none !important;
}
.css-55vwbm-MuiTimeline-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: start !important;
  padding: 6px 16px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.-start-3 {
  inset-inline-start: -0.99rem !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #168004 !important;
}

/* drop down arrow */
.rotate-icon {
  transform: rotateX(180deg);
}
