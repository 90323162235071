.login-title {
  color: #168004;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5px;
}
.form-input-login {
  width: 100%;
  height: 45px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .login-title {
    font-size: 30px;
  }
}
.login-form {
  display: block;
  margin: auto;
}
.signUp-button {
  width: 295px;
  height: 48px;
  border-radius: 8px;
  background: #168004;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.543px;
  text-transform: uppercase;
}
.signup-text {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.signup-text-details {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.login-button {
  border-radius: 8px;
  background: #3e8936;
  height: 50px;
  display: block;
  color: #fafafb;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5px;
}
.form-input {
  width: 92%;
  height: 45px;
}
@media (max-width: 768px) {
  .form-input {
    width: 100%;
  }
}
.form-input:focus {
  background-color: #17800464;
}

.form-label {
  color: var(--Grey, #808d9e);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: start;
}
.forgot-password {
  color: #3e8936;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.5px;
}
.social-media {
  color: #212121;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
}
.image {
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 0 100%, 1% 100%);
}

@media only screen and (max-width: 1024px) {
  .image {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .image {
    display: none;
  }
}

.background-image-login {
  background-size: 50%;
  background-position: top left;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
}

.background-login {
  background-image: url("../../assets/images/bgLogin.png");
  background-repeat: no-repeat;
}

.input-container {
  position: relative;
}

.iconLogin {
  width: 1.4em;
  height: 1.4em;
  position: absolute;
  top: 50%;
  right: 5em;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .iconLogin {
    width: 1.2em;
    height: 1.2em;
    right: 2em;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .iconLogin {
    width: 1.4em;
    height: 1.4em;
    right: 4em;
  }
}
.fb-login-button {
  border-radius: 8px;
  border: 1px solid var(--neutral-300, #eff0f6);
  background: var(--neutral-100, #fff);
  width: 320px;
  height: 50px;
  box-shadow: 0px 1.99393px 5.98178px 0px rgba(19, 18, 66, 0.07);
}
.facebook-image {
  width: 30px;
  height: 30px;
}
.fb-button-content {
  display: block;
  margin: auto;
}
