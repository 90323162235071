.icon-bg {
  background-color: rgba(22, 128, 4, 0.13);
}

.feature-card {
  cursor: pointer;
  transition: all;
  transition-duration: 100ms;
}

.feature-card:hover {
  border-radius: 14.646px;
  border: 0.915px solid #168004;
  background: rgba(22, 128, 4, 0.03);
  box-shadow: 0px 10.985px 92.5px 0px rgba(22, 128, 4, 0.07);
}

@media screen and (min-width: 1400px) {
  .feature-card:nth-child(4),
  .feature-card:nth-child(5) {
    max-width: 500px;
  }
}
