.background-testimonials-section{
    background-image: url("../../../assets/images/bg-section2.svg");
    padding: 40px;
}

.video-testimonials{
    width: 100%;
    max-width: 1000px;
    height: 615px;
    border-radius: 14px;
}

.testimonials-section-button{
    border-radius: 8px;
    background: #168004;
    width: 180px;
    height: 50px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}