footer {
  background: rgba(22, 128, 4, 0.02);
  padding-top: 80px;
  padding-bottom: 20px;
}

li {
  color: var(--Dark-Black, #343434);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
}

.media {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.08);
  padding: 12px;
  transition: all;
  transition-duration: 100ms;
}

.media:hover {
  background-color: #168004;
}
.media:hover, .media:active {
  &.youtube img {
    content: url('../../../assets/icons/youtube-white.svg');
  }

  &.github img {
    content: url('../../../assets/icons/github-white.svg');
  }

  &.tindie img {
    content: url('../../../assets/icons/tindie-white.svg');
  }
}

@media screen and (max-width: 330px) {
  .media-container {
    flex-wrap: wrap;
  }
}
