.background-sensors-section{
    background: #FAFDFA;
    padding: 40px;
}

.sensors-list-image{
    border-radius: 16px;
    max-width: 1100px;
    max-height: 500px;
    border: 1px solid #168004;
    width: 100%;
}

.sensors-section-button{
    border-radius: 8px;
    background: rgba(22, 128, 4, 0.16);
    width: 214px;
    height: 64px;
    gap: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #168004;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .rotation{
        transform: rotate(-45deg);
    }
}